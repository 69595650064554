import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// importing all the themes
import Hometheme from "../themes/home-theme";
import Contenttheme from "themes/contect-theme";
import Errorpagetheme from "themes/error-theme";
import Blogtheme from "themes/blog-theme";
import Blogdetailstheme from "themes/blogdetails-theme";
import Videotheme from "themes/video-theme";




class MyRouts extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Hometheme />} />
          <Route exact path="/page" element={<Contenttheme />} />
          <Route exact path="/blog" element={<Blogtheme />} />
          <Route exact path="/blogdetail" element={<Blogdetailstheme />} />
          <Route exact path="/video" element={<Videotheme />} />

          <Route exact path="/:slug" element={<Contenttheme />} />




          <Route exact path="*" element={<Errorpagetheme />} />

        </Routes>
      </BrowserRouter>
    );
  }
}
export default MyRouts;
