import React from "react";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
import Videopage from "Pages/Videopage";



export default function Videotheme() {

    return (
        <>
        <Header/>
            <Videopage/>
        <Footer/>
        </>
    );
}
