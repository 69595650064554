import axios from "axios";
import { GET_CONTENT, GET_SETTINGS, POST_CONTACT_US } from "./url_helper";

export const postContactUsData = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.post(POST_CONTACT_US, data);
            if (response?.data?.status === "failure") {
                reject(response.data?.message);
            }
            resolve(response.data);
        } catch (error) {
            reject(error?.message || "Something went wrong!");
        }

    });
}


export const getSettings = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.get(GET_SETTINGS);
            if (response?.data?.status === "failure") {
                reject(response.data?.message);
            }
            resolve(response.data.data);
        } catch (error) {
            reject(error?.message || "Something went wrong!");
        }
    });
}


export const getContentPage = async (type) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.get(GET_CONTENT + "?type=" + type);
            if (response?.data?.status === "failure") {
                reject(response.data?.message);
            }
            resolve(response.data.data);
        } catch (error) {
            reject(error?.message || "Something went wrong!");
        }
    });
}




