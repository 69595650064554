import React from "react";
import Errorpage from "Pages/404errorpage";



export default function Errorpagetheme() {

    return (
        <>
            <Errorpage/>
        </>
    );
}
