import React, { createContext, useEffect, useState } from "react";

export const SettingsContext = createContext();

export default function SettingsProvider({ children }) {
  const [settings, setSettings] = useState(null);


  return (
    <SettingsContext.Provider value={[settings, setSettings]}>{children}</SettingsContext.Provider>
  );
}
