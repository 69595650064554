import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";


export default function Videofullmod(props) {
    const navigate = useNavigate();
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="fullvideo"
    >
      <Modal.Body>
         <a href="#" onClick={props.onhide} className="full_vedioclose">
         <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#fff" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"/></svg>
         </a>
         <ReactPlayer
            url={props.currentVideo}
            playing
            controls
            width="100%"
            height="100%"
          />
      </Modal.Body>
    </Modal>
  );
}
