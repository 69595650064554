import { StrictMode } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import SettingsProvider from "context/SettingsProvider";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <SettingsProvider>
      <App />
    </SettingsProvider>
  </StrictMode>,
  rootElement
);

reportWebVitals();
