import { SettingsContext } from "context/SettingsProvider";
import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Fade } from "react-reveal";


const defaultData = {
  title: "Available on Metapro",
  leftHeading: "Coming Soon",
  leftSubHeading: "The Sport Karate History Portal",
  backgroundImage: "",
  rightForgroundImage: "/images/oclulus.png"
};

const Comingsoon = () => {
  const [sectionSeven, setSectionSeven] = useState(defaultData);

  const [settings] = useContext(SettingsContext);

  useEffect(() => {
    if (settings?.sectionSeven) {
      const data = {
        title: settings?.sectionSeven?.title || defaultData.title,
        leftHeading: settings?.sectionSeven?.leftHeading || defaultData.leftHeading,
        leftSubHeading: settings?.sectionSeven?.leftSubHeading || defaultData?.leftSubHeading,
        backgroundImage: settings?.sectionSeven?.backgroundImage || defaultData?.backgroundImage,
        rightForgroundImage: settings?.sectionSeven?.rightForgroundImage || defaultData?.rightForgroundImage,
      }
      setSectionSeven(data);
    }
  }, [settings])

  return (
    <Fade>
      <>
        <div className="availbale_head text-center py-5">
          <h4>{sectionSeven?.title}</h4>
        </div>
        <section className="Comingsoon_sec comon_pad">
          <Container>
            <div className="Comingsoon_innner">
              <div className="coming-soon-head">
                <h1>{sectionSeven?.leftHeading}</h1>
                <p className="text-white px-2 m-0">{sectionSeven?.leftSubHeading}</p>
              </div>
              <div className="oculus">
                <img src={sectionSeven?.rightForgroundImage} alt="" className="img-fluid" />
              </div>
            </div>
          </Container>
        </section>
      </>
    </Fade>
  );
};

export default Comingsoon;
