import React, { useState, useEffect, useRef, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { Fade } from "react-reveal";
import { SettingsContext } from "context/SettingsProvider";



export const defaultData = {
  question: "What is SetVR - XL Unleashed?",
  description: `SetVR XL is an intriguing, visually captivating, and stunningly
  life-like game with a hidden talent! That talent would be the
  opportunity to enhance one’s physical fitness, muscle memory,
  reflexes, and stamina, as well as to sharpen mindfulness. Anyone who
  is interested in practicing self defense will rise to the challenges
  in the game as they enjoy the many chances to improve their
  techniques and fitness levels.`,
  backgroundImage: "/images/setvrbg_pattern.png",
  leftForgroundImage: "/images/blackboximg.png",
  rightForgroundImage: [
    {
      image: "/images/Component1.png",
      description:
        "“So, Cade….where is this martial arts place you were so hot for us to see? We’ve been walking for hours; I’m gettin’ tired.”",
    },
    {
      image: "/images/Component2.png",
      description:
        " “Relax, Ben. Sorry I lost the map, but a big school shouldn’t be that hard to find. Not too many martial arts schools in the area…ya know!?”",
    },
    {
      image: "/images/Component3.png",
      description:
        "“OK, guys! Mara and I are getting hungry as well as tired - and I think I see something up ahead!”",
    },
    {
      image: "/images/Component4.png",
      description:
        "“Right, Kiri; I see it, too! But instead of some kind of regular building it almost looks like some kind of a castle from here. These woods are getting creepy now, so let’s take a chance and get there before it gets dark!”",
    }
  ],
  sliderBackgroundImage: "/images/patternline.png",
  afterSliderStar: "/images/yellow.png"
};

const Kickstarter = () => {
  const [sectionTwo, setSectionTwo] = useState(defaultData);
  const [settings] = useContext(SettingsContext);
  useEffect(() => {
    if (settings?.sectionTwo) {
      const data = {
        question: settings?.sectionTwo?.question || defaultData?.question,
        description: settings?.sectionTwo?.description || defaultData?.description,
        backgroundImage: settings?.sectionTwo?.backgroundImage || defaultData?.backgroundImage,
        leftForgroundImage: settings?.sectionTwo?.leftForgroundImage || defaultData?.leftForgroundImage,
        rightForgroundImage: settings?.sectionTwo?.rightForgroundImage?.length ? settings?.sectionTwo?.rightForgroundImage : defaultData?.rightForgroundImage,
        sliderBackgroundImage: settings?.sectionTwo?.sliderBackgroundImage || defaultData?.sliderBackgroundImage,
        afterSliderStar: settings?.sectionTwo?.afterSliderStar || defaultData?.afterSliderStar
      };
      setSectionTwo(data);
    }
  }, [settings])

  const sliderSettings = {
    dots: false,
    arrows: false,
    fade: true,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 9000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Fade>
      <section
        id="targetSection"
        className="Kickstarter_col comon_pad"
        // style={{ backgroundImage: sectionTwo?.backgroundImage }}
        style={{ backgroundImage: `url(${sectionTwo?.backgroundImage})` }}

      >
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div className="kickstrater_left">
                {/* <h3>Kickstarter Coming Soon</h3> */}

                <div className="blckbox_img">
                  <img
                    src={sectionTwo?.leftForgroundImage}
                    alt=""
                    className="img-fluid"
                  />
                </div>

                {/* <div className="audio mt-5">
                  {isAudioplay && (
                    <audio controls autoplay>
                      <source
                        src="../images/SetVRsound.mp3"
                        type="audio/mpeg"
                      />
                    </audio>
                  )}
                </div> */}

                {/* <div className="get_notify">
                <a
                  href="https://www.kickstarter.com/projects/setvr/setvr/"
                  target="_blank"
                >
                  Get Notified at Launch
                </a>
              </div> */}
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className="kickstrater_right">
                {/* <p>
                SET VR will be launching a Kickstarter soon to help take the app
                to the next level. The Kickstarter will feature special deals on
                new SET VR content, merchandise, and more!
              </p> */}

                <div className="slider_right position-relative">
                  <div className="pattern_line">
                    <img
                      src={sectionTwo?.sliderBackgroundImage}
                      alt=""
                      className="img-fluid w-100"
                    />
                  </div>
                  <Slider {...sliderSettings}>
                    {sectionTwo?.rightForgroundImage?.map((item, idx) => {
                      return (
                        <div>
                          <div className="img_div position-relative">
                            <div className="white_box"></div>
                            <img
                              src={item?.image}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <p className="m-0 stroy_text">
                            {item?.description}
                          </p>
                        </div>
                      );
                    })}

                    {/* <div>
                      <div className="img_div position-relative">
                        <div className="white_box"></div>
                        <img
                          src="/images/setvrxl.png"
                          alt=""
                          className="img-fluid setlogo"
                        />
                      </div>
                    </div> */}
                  </Slider>

                  <ul className="yellow_dots">
                    <li>
                      <img
                        src={sectionTwo?.afterSliderStar}
                        alt=""
                        className="img-fluid"
                      />
                    </li>
                    <li>
                      <img
                        src={sectionTwo?.afterSliderStar}
                        alt=""
                        className="img-fluid"
                      />
                    </li>
                    <li>
                      <img
                        src={sectionTwo?.afterSliderStar}
                        alt=""
                        className="img-fluid"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fade>
  );
};

export default Kickstarter;
