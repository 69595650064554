import React from 'react'
import { Link } from 'react-router-dom'

const Errorpage = () => {
  return (
    <div className='error_page position-relative'>

        <div className="error_inner text-center">
         <h3>404</h3>
         <h4>Page not found.</h4>
         <p>Please check the URL and try again.</p>
         <div className="visit_wrap justify-content-center"><Link to="/" target="_blank" class="btn-common visit_btn">Back to Homepage</Link></div>
        </div>
    </div>
  )
}

export default Errorpage