import { SettingsContext } from "context/SettingsProvider";
import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";



const defaultData = {
  leftVideo: "/images/setvrTrailer.mp4",
  rightHeading: "Daily Exercise from Start to Finish",
  rightDescription: `Not a big fan of exercise?  No time to go to the gym? Can't find a sparring partner? Here is your chance to practice sparring with virtual attackers --or with a real person working their own avatar! -- and to get your body moving! Experience various scenarios as you help to build up muscle strength and reflex responses. Be good to your body and have fun while you practice!`,
  bottomRightSubHeading: "/images/blue-bedge.jpeg",
};

const Excercise = () => {
  const [sectionFour, setSectionFour] = useState(defaultData);

  const [settings] = useContext(SettingsContext);

  useEffect(() => {
    if (settings?.sectionFour) {
      const data = {
        leftVideo: settings?.sectionFour?.leftVideo || defaultData?.leftVideo,
        rightHeading: settings?.sectionFour?.rightHeading || defaultData?.rightHeading,
        rightDescription: settings?.sectionFour?.rightDescription || defaultData?.rightDescription,
        bottomRightSubHeading: settings?.sectionFour?.bottomRightSubHeading || defaultData?.bottomRightSubHeading,
      };
      setSectionFour(data);
    }
  }, [settings])

  return (
    <section className="Excercise_Sec comon_pad">
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <div className="leftvedio_here position-relative">
              {sectionFour?.leftVideo &&
                <video height="400" autoPlay loop muted controls>
                  <source src={sectionFour?.leftVideo} type="video/mp4" />
                </video>
              }
            </div>
          </Col>

          <Col lg={6} md={6} sm={12}>
            <div className="leftvedio_content">
              <h3>{sectionFour?.rightHeading}</h3>
              <p>
                {sectionFour?.rightDescription}
              </p>

              <div className="img-coverd2">
                <img
                  src={sectionFour?.bottomRightSubHeading}
                  alt=""
                  className="img-fluid"
                />
              </div>
              {/* <p className="five_line_down"><b>S</b>elf <b>E</b>nhancement <b>T</b>raining VR</p> */}

              {/* <Button className="btn-common mt-5">Learn More</Button> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Excercise;
